<template>
  <v-card
    flat
    :ripple="false"
    tile
  >
    <v-list-item
      two-line
    >
      <v-list-item-content>
        <v-list-item-title
          class="break-word"
        >
          {{ formattedItem.title }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-snip:js="5"
          class="break-word"
          v-html="formattedItem.subtitle"
        />
      </v-list-item-content>
      <b10-list-item-right-avatar>
        {{ formattedItem.importe_articulos|currency }}
      </b10-list-item-right-avatar>
    </v-list-item>
    <v-card-actions>
      <v-chip
        label
        :color="formattedItem.colorEstado"
        dark
        small
        class="mr-1 mt-1"
      >
        {{ formattedItem.est_presupuestocli_descripcion }}
      </v-chip>
      <v-chip
        v-if="formattedItem.importe_cuotas > 0"
        label
        color="info"
        dark
        small
        class="mr-1 mt-1"
      >
        {{ formattedItem.importe_cuotas|currency }} (cuotas)
      </v-chip>
      <v-chip
        v-if="formattedItem.estado_financiero !== PRESUPUESTOCLI.estadosFinancieros.noNecesitaAprobacion"
        label
        :color="PRESUPUESTOCLI.coloresEstadosFinancieros[formattedItem.estado_financiero]"
        dark
        small
        class="mr-1 mt-1"
      >
        <v-icon
          left
          small
        >
          {{ $vuetify.icons.values.estudioFinanciero }}
        </v-icon>
        {{ PRESUPUESTOCLI.descripcionesEstadosFinancieros[formattedItem.estado_financiero] }}
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from '@/utils/lodash'
import { PRESUPUESTOCLI } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      PRESUPUESTOCLI,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.presupuestocli.title(item)
      item.subtitle = this.$online.presupuestocli.subtitle(item)
      item.fechaPresupuesto = `${this.$options.filters.shortDate(item.fpresupuesto)}`
      if (item.idclasificacion_estado === PRESUPUESTOCLI.clasificacionEstados.aceptado) {
        item.colorEstado = PRESUPUESTOCLI.colores.aceptado
      } else if (item.idclasificacion_estado === PRESUPUESTOCLI.clasificacionEstados.rechazado) {
        item.colorEstado = PRESUPUESTOCLI.colores.rechazado
      } else {
        item.colorEstado = PRESUPUESTOCLI.colores.pendiente
      }
      return item
    },
  }
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
